@import  "../../variabels.css";

.section{
  height: 100vh;            /* fall-back */
  height: -moz-available;
  /*height: -webkit-fill-available;*/
  height: stretch;
}

.container{
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: var(--main-text-color);
  font-family: 'Albert Sans', sans-serif;
  font-size: var(--text-medium);
  box-sizing: border-box;
  padding: 10px 50px 0 50px;
  padding-bottom: 100px;
}

.inner_container{
  max-width: 1200px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.inner_container h4 {
  color: var(--main-text-color-dark);
  font-size: var(--text-small);
  /*font-size: clamp(12px,0.5em,0.5em);*/
  line-height: 0.9;
  margin: 0 0 0.5em 0;
  width: 80%;
  text-align: center;
}

.inner_container h3{
  color: var(--main-text-color);
  font-size: var(--text-medium);
  /*font-size: clamp(1em,1.5em,1.5em); */
  line-height: 0.9;
  margin: 0;
  width: 80%;
  text-align: center;
}

.inner_container p{
  text-align: center;
}