@import  "../../variabels.css";

.container{
  height: 100vh;
  font-size: 35px;
  display: flex;
  align-items: center;
}

.inner{
  margin-left: 25px;
}

.container p {
  color: var(--main-text-color-dark);
  font-size: clamp(12px,0.5em,0.5em);
  line-height: 0.9;
  margin: 0 0 0.5em 0;
}

.container h1{
  color: var(--main-text-color);
  font-size: clamp(1em,1.5em,1.5em); 
  line-height: 0.9;
  margin: 0;
}

@media only screen and (min-width: 480px) {

  .container{
    font-size: 50px;
  }

  .inner{
    margin-left: 50px;
  }  
}

@media only screen and (min-width: 700px) {
  .inner{
    margin-left: 100px;
  }  
}


.box{
	position: absolute;
	left: 50%;
	transform: translate(0, -50%);
  bottom: 50px;
}

.box span{
	display: block;
	width: 20px;
	height: 20px;
	border-bottom: 2px solid var(--main-text-color);
	border-right: 2px solid var(--main-text-color);
	transform: rotate(45deg);
	margin: -10px;
	animation: animate 2s infinite;
}

.box span:nth-child(2)
{
	animation-delay: -0.2s;
}

.box span:nth-child(3)
{
	animation-delay: -0.4s;
}

@keyframes animate{
	
	0%{
		opacity: 0;
		transform: rotate(45deg) translate(-20px, -20px);
	}
	50%{
		opacity: 1;
	}
	100%{
		opacity: 0;
		transform: rotate(45deg) translate(20px, 20px);
	}
}