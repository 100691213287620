@import "../../variabels.css";

.button{
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
  box-shadow: 0px 0px 0 var(--main-text-color-dark);
  border-radius: 5px;
  position: relative;
  border-color: var(--main-text-color);
  font-size: var(--text-small);
  padding: 30px 50px;
  transition: box-shadow 500ms ease 0s, transform 500ms ease 0s;
}

.button:hover{
  box-shadow: -4px 4px 0 var(--main-text-color);
  transform: translateY(-4px) translateX(4px);
}

.button:active{
  box-shadow: -2px 2px 0 var(--main-text-color-dark);
  transform: translateY(-2px) translateX(2px);
}