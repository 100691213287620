
.innerContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1em 1em;
}

@media only screen and (min-width: 700px) {
  .innerContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em 1em;
  }
}
