@import "../../variabels.css";

.button {
  background-color: var(--main-background-color);
  border: none;
  position: fixed;
  right:20px
}

.button i {
  color: var(--main-text-color);
  font-size: 44px;
  margin: auto;
}

.container{
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(16, 16, 50, .1);
  backdrop-filter: blur(10px);
  z-index: 999;
  padding: 1em;
}

.langSwitchContainer{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;
}

.navContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 1rem;
  width: 100%;
}

.navContainer a{
  color: var(--main-text-color);
  text-decoration: none;
}

.navContainer ol { 
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.navContainer li {
  counter-increment: item 1;
  text-align: center;
  margin: 30px;
}

.navContainer li ::before{
  content: "0" counter(item) ".";
  display: block;
  margin-bottom: 5px;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 1rem;
  width: 100%;
}

.links li {
  margin-bottom: 30px;
  list-style-type: none;
}

.links i {
  font-size: 44px;
  color: var(--main-text-color);
}