@import  "../../variabels.css";

.container{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  color: var(--main-text-color);
  font-family: 'Albert Sans', sans-serif;
  font-size: var(--text-medium);
  box-sizing: border-box;
  padding: 10px 50px 0 50px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 700px) {
  .container{
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;
  }
}