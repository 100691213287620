.canvasScrollLayer {
  z-index: 999;
  position: absolute;
  width: calc(100% - 70px);
  height: 400px;
}

.containerStage {
  display: flex;
  justify-content: center;
  align-items: center;
}
