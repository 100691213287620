@import "../../variabels.css";

/* Need to redo color !! */

.line {
  height: 2px;
  background-color: var(--main-text-color-dark);
  display: flex;
  margin-bottom: 50px;
}

.titleholder{
  background-color: var(--main-text-color-dark);
  height: 30px;
  float: left;
  display: flex;
  font-family: 'Fira Code', monospace;
  font-size: 1.5em;
  color: var(--secondary-text-color);
  text-align: center;
}

.titleholder h2::after{
  z-index: -200;
  position: absolute;
  vertical-align: middle;
  content: "";
  width: 0;
  height: 0;
  border-top: 0.75em solid var(--main-text-color-dark);
  border-right: 0.75em  solid transparent;
  border-bottom: 0.75em solid transparent;
  border-left: 0.75em solid var(--main-text-color-dark);
  margin-left: 5px;
}

.titleholder h2{
  background-color: var(--main-text-color-dark);
  font-size: 1em;
  height: 1.5em;
  margin-left: 5px;
  font-weight: 400;
  padding: 0 5px;
  margin: 0;
}

.titleholder p{
  margin: 0px;
  position: relative;
  overflow: visible;
  z-index: 1;
  height: 1.5em;
  font-size: 1em;
  padding: 0 5px;
  background-color: var(--main-text-color-dark-dark);
}