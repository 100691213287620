@import "../../variabels.css";

.header {
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  display: flex;
  background: rgba(16, 16, 50, 0.1);
  backdrop-filter: blur(10px);
  position: fixed;
  flex-direction: row-reverse;
  align-items: center;
  z-index: 998;
}

.button {
  background-color: var(--main-background-color);
  border: none;
  height: 46px;
}

.button i {
  color: var(--main-text-color);
  font-size: 44px;
  margin: auto;
}

.languageInfo {
  display: flex;
  justify-content: center;
  flex-direction: row;
  top: 80px;
  right: 40px;
  position: fixed;
  color: var(--main-text-color);
  white-space: pre-line;
}

.languageInfo p {
  margin: 0px;
}

.languageInfo i {
  font-size: 1.5em;
  margin-left: 10px;
}
