:root{
  
  --main-bg-color: #041526;
  --secondary-bg-color: #082441;
  --main-bg-color-dark:  #030F1B;
  --main-text-color: #75B1F0;
  --main-text-color-dark:#4796EB;
  --main-text-color-dark-dark:#1463B8;
  --secondary-text-color: #F4F5F6;
  --secondary-text-color-dark: #E0E1E2;
  --contrast-color: #E63946;

  --selected-tech-color:#11D05D;
  --high-relation-tech-color:#2FEE7C;
  --medium-relation-tech-color:#6CF3A3;

  --border-contrast-color:#4C739C;

  --slate: #8892b0;

  --screen-tabletS: 600px;

  --text-small: 1em;
  --text-medium: 1.5em;
  --text-big: 2em;

  --bp-l: 700px;
  --bp-m: 600px:
}

.selectedTechColor{
  color: var(--selected-tech-color);
}

.highRelationTechColor{
  color: var(--high-relation-tech-color);
}

.mediumRelationTechColor{
  color: var(--medium-relation-tech-color);
}
