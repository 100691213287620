@import  "../../variabels.css";

.container{
  width: 100%;
  min-height: 100vh;
  display: grid;
  flex-wrap: wrap;
  color: var(--main-text-color);
  font-family: 'Albert Sans', sans-serif;
  font-size: var(--text-medium);
  box-sizing: border-box;
  padding: 10px 50px 0 50px;
  padding-bottom: 100px;
}