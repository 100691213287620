@import "../../variabels.css";

.picture{
  border-radius: 5px;
  transition: box-shadow 500ms ease 0s, transform 500ms ease 0s;
  max-width:100%;
  max-height:100%;
}

.picture:hover{
  transform: translateY(10px) translateX(10px);
}

/* .frame:hover::after {
  top: 10px;
  left: 10px;
} */

.frame:after {
  border: 2px solid var(--main-text-color);
  top: 20px;
  left: 20px;
  z-index: -1;
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  transition: top 200ms ease, left 200ms ease;
}

.frame{
  display: block;
  position: relative;
  max-width: 80vw;
  max-height: 80vw;
  width: fit-content;
  height: fit-content;
  margin-top: 1em;
}


@media only screen and (min-width: 700px) {
  .picture{
    width: 100%;
  }
}

@media only screen and (min-width: 460px) and (max-width: 700px){
    .picture{
    width: 80vw; 
    height: 80vw;
  }
}
